<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <ByLedLogo />

      <!--Контент слева-->
      <PictureComp>
        <b-img
            fluid
            src='@/assets/images/pages/not-authorized.svg'
            alt="Login V2"
        />
      </PictureComp>

      <!--Контент (справа)-->
      <AccountConfirmTab />

    </b-row>
  </div>
</template>

<script>
import { BImg, BRow } from 'bootstrap-vue'
import PictureComp from '@/components/core/PictureComp.vue'
import LoginForm from '@/components/views/user/login/LoginForm.vue'
import ByLedLogo from '@/components/core/ByLedLogo.vue'
import AccountConfirmTab from '@/components/views/user/confirm/AccountConfirmTab'

export default {
  name: "Account-confirm",
  components: {
    ByLedLogo,
    LoginForm,
    PictureComp,
    BRow,
    BImg,
    AccountConfirmTab,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
