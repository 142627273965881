<template>
  <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
    <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">

      <!--   Заголовок   -->
      <b-card-title title-tag="h2" class="font-weight-bold mb-1 fill">
        Добро пожаловать в конфигуратор ByLed!
      </b-card-title>
      <b-card-text class="mb-2">
        Пожалуйста введите ваши учетные данные и начните работу
      </b-card-text>

      <!-- Форма -->
      <validation-observer v-slot="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent>
          <BlInput
            name="email"
            label="Email"
            rules="required|email"
            placeholder="email@gmail.com"
            input-type="text"
            v-model="email"
          ></BlInput>
          <BlInput
            name="password"
            label="Пароль"
            rules="required|min:8"
            placeholder="........."
            input-type="password"
            v-model="password"
          >
            <template>
              <b-link :to="{ name: 'forgot' }">
                <small>Забыли пароль?</small>
              </b-link>
            </template>
          </BlInput>
          <b-form-group>
            <b-form-checkbox
              id="remember-me"
              v-model="status"
              name="checkbox-1"
            >
              Запомнить меня
            </b-form-checkbox>
          </b-form-group>
          <b-button
            type="submit"
            variant="primary"
            block
            :disabled="invalid"
            @click="login()"
          >
            Войти
          </b-button>
        </b-form>
      </validation-observer>

      <!-- Карта "новый пользователь"      -->
      <b-card-text class="text-center mt-2">
        <span>Новый пользователь? </span>
        <b-link :to="{ name: 'register' }">
          <span>&nbsp;Создать аккаунт</span>
        </b-link>
      </b-card-text>
    </b-col>
  </b-col>
</template>

<script>

import '@/libs/custom-validate'
import api from '@/api';
import BlInput from "@/components/controls/BlInput";

import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BLink,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import utils from '@/utils';
export default {
  name: "LoginForm",
  components: {
    BlInput,
    BCol,
    BLink,
    BFormGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      email: "",
    };
  },
  computed: {},
  methods: {
    login() {
      const self = this;
      api.auth
        .signin(this.email, this.password)
        .then((data) => {
          api.users.me().then((data) => {
            self.$store.commit("app/SET_USER", data.data.result);
            self.$router.push("/account-settings");
          });
        })
        .catch((err) => {
          const data = err.response.data;
          if (data.error && data.errorCode == 103) {
            api.users.me().then((data) => {
              self.$store.commit("app/SET_USER", data.data.result);
              self.$router.push("/account-settings");
            });

            return;
          }
          utils.notificate(self, data.message, `Код ошибки: ${data.errorCode}`, 'danger');
        });
    },
  },
};
</script>

<style scoped>
.fill{
  margin-top: 100px;
}
</style>
