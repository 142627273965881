<template>
  <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
    <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
      <!--   Заголовок   -->
      <div v-if="!is_loading">
        <div v-if="is_ok">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1 fill">
            Ваш email подтвержден!
          </b-card-title>
          <b-card-text class="mb-2"> Теперь вы можете войти </b-card-text>

          <!-- Форма -->
          <b-button type="submit" variant="primary" block @click="toAuth()">
            Перейти ко входу
          </b-button>
        </div>
        <div v-else>
          <b-card-title title-tag="h2" class="font-weight-bold mb-1 fill">
            Что-то пошло не так :(
          </b-card-title>

        </div>
      </div>
      <div v-else class="spinner-parent">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
        ></b-spinner>
      </div>
    </b-col>
  </b-col>
</template>

<script>
import BlInput from "@/components/controls/BlInput";
import api from "@/api";
import utils from "@/utils";
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BLink,
  BSpinner,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  name: "LoginForm",
  components: {
    BlInput,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BButton,
    BSpinner,
  },
  computed: {},
  data() {
    return {
      is_loading: true,
      is_ok: true,
    };
  },
  mounted() {
    const self = this;
    const confirm_key = this.$route.query.confirm_key;
    api.auth
      .confirm(confirm_key)
      .then((response) => {
        self.is_loading = false;
        if (!response.data.error) {
        } else {
          self.is_ok = false;
        }
      })
      .catch((err) => {
        self.is_loading = false;
        self.is_ok = false;
        const data = err.response.data;
        if (data.message) {
          utils.notificateError(
            self,
            data.message,
            `Код ошибки: ${data.errorCode}`
          );
        }
      });
  },

  methods: {
    toAuth() {
      const self = this;
      self.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.fill {
  margin-top: 100px;
}
.spinner-parent {
  display: flex;
  justify-content: center;
}
</style>
